import { useCallback, useEffect, useMemo } from "react";
import { AFrame } from "../aFrame";
import eventBus from "../events";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { addPart } from "../store/globalState";
import { Product } from "../types";
import isMobile from "../utils/isMobile";

type Props = {
  aFrame: AFrame | null;
  showDetails: (partDetails: { name: string; productId: string }) => void;
  products: Product[];
  hideProducts: () => void;
  searchFilter?: string;
};

const jumpingAnimationAsset = "/assets/arrow-down.png";

export default function Products({
  aFrame,
  showDetails,
  searchFilter,
  products,
  hideProducts,
}: Props) {
  const selectedArea = useAppSelector((state) => state.area.selectedArea);
  const selectedProducts = useAppSelector(
    (state) => state.products.productsAdded
  );
  const selectedProduct = selectedProducts[selectedArea?.area || ""];

  const currentArea = useAppSelector((state) => state.area.selectedArea);

  // const autoAddedProductsToArea = useAppSelector(
  //   (state) => state.misc.autoAddedProductsToArea
  // );

  // const isZoomedIn = useRef<boolean>(false);

  const dispatch = useAppDispatch();

  const setSelectedPart = useCallback(
    (partId: string | undefined) => {
      selectedArea && dispatch(addPart({ [selectedArea.area]: partId }));
    },
    [dispatch, selectedArea]
  );

  const addPartToStyle = useCallback(
    async (product: Product, productVariantName?: string) => {
      console.log("variant", productVariantName);

      isMobile() && (await aFrame?.resetCamera(true));
      aFrame?.clearCurrentElements("other");
      // const position =
      //   currentArea?.productPositions && currentArea?.productPositions[0];

      const backgroundVariantKey = product.extraParams?.find(
        (x) => x.key === "background_variant"
      )?.value;

      const variant = currentArea?.options?.find(
        (x) => x.key === backgroundVariantKey
      );

      const productPositionKey = product.extraParams?.find(
        (x) => x.key === "position"
      )?.value;

      const productPosition =
        variant?.productPositions?.find((x) => x.key === productPositionKey)
          ?.position ||
        currentArea?.productPositions?.find((x) => x.key === productPositionKey)
          ?.position ||
        (currentArea?.productPositions &&
          currentArea?.productPositions[0].position);

      if (productPosition && variant) {
        // quick fix for the case when background is changed but sink doesn't change
        setTimeout(() => {
          aFrame?.addCubeMapSky(variant.image360Url);
        }, 100);
      }

      const { x, y, z, xRotation, yRotation, zRotation } = productPosition || {
        x: 0,
        y: 0,
        z: 0,
        xRotation: 0,
        yRotation: 0,
        zRotation: 0,
      };

      const onVariantClicked = () => {
        let currentVariant = null;
        const splitProduct = selectedProduct?.split("-");
        if (splitProduct && splitProduct.length > 0) {
          const variantName = splitProduct[1];
          if (product && product.variants) {
            currentVariant = product.variants?.find(
              (variant) => variant.name === variantName
            );
          }
        }

        if (
          currentVariant &&
          currentVariant.name &&
          currentVariant.helloARProductId
        ) {
          showDetails({
            name: currentVariant.name,
            productId: currentVariant.helloARProductId,
          });
        } else {
          showDetails({
            name: product.name,
            productId: product.helloARProductId,
          });
        }
      };

      const menu = aFrame?.addHoverMenu(
        [
          {
            text: "learn more",
            imageUrl: "/assets/learn_more.png",
            onClick: onVariantClicked,
          },
          {
            text: "remove",
            imageUrl: "/assets/remove.png",
            onClick: () => {
              aFrame.clearCurrentElements("other");
              setSelectedPart(undefined);
            },
          },
        ],
        "hover-menu",
        `${x} ${y} ${z}`,
        `${xRotation || 0} ${yRotation || 0} ${zRotation || 0}`,
        `${x - 1} ${y + 0.5 + 5 / 2} ${z}`
      );

      const renderImage =
        product.variants?.find((variant) => variant.name === productVariantName)
          ?.renderedImageUrl || product.renderedImageUrl;

      const res = await fetch(renderImage);
      const data = await res.blob();
      const blobUrl = URL.createObjectURL(data);

      product.renderedImageUrl &&
        aFrame?.addImage({
          imageUrl: blobUrl,
          id: product._id,
          position: `${x} ${y} ${z}`,
          rotation: `${xRotation || 0} ${yRotation || 0} ${zRotation || 0}`,
          width: `${productPosition?.width}` || "1",
          height: `${productPosition?.height}` || "1",
          onClick: () => {
            aFrame.removeImage(product._id + "jumping-annotation");

            // if (isZoomedIn.current) {
            //   aFrame.resetCamera(true);
            //   aFrame.setCameraPosition({ x: 0, y: 0, z: 0 });
            //   isZoomedIn.current = false;
            //   eventBus.emit("zoomOut");
            // } else {
            //   aFrame.resetCamera(true);
            //   aFrame.setCameraPosition({
            //     x: 0,
            //     y: y,
            //     z: -3,
            //   });
            //   isZoomedIn.current = true;
            //   eventBus.emit("zoomIn");
            // }
          },
          animateType: undefined,
          onMouseEnter: () => {
            menu?.showMenu();
            aFrame.removeImage(product._id + "jumping-annotation");
          },
          onMouseLeave: () => {
            menu?.hideMenu();
          },
        });

      const annotationPosition = `${x} ${y + 0.9 + 3.03 / 2} ${z}`;

      aFrame?.addImage({
        imageUrl: jumpingAnimationAsset,
        id: product._id + "jumping-annotation",
        position: annotationPosition,
        rotation: `${xRotation || 0} ${yRotation || 0} ${zRotation || 0}`,
        width: "0.5",
        height: "0.5",
        onClick: undefined,
        animateType: "bounceY",
      });

      eventBus.emit("partAdded");
    },

    [
      aFrame,
      currentArea?.options,
      currentArea?.productPositions,
      setSelectedPart,
      showDetails,
      selectedProduct,
    ]
  );

  useEffect(() => {
    if (selectedProduct) {
      const productId = selectedProduct.split("-")[0];
      const variantName = selectedProduct.split("-")[1];
      const product = products?.find(({ _id }) => _id === productId);

      if (product) {
        addPartToStyle(product, variantName);
      }
    }
  }, [addPartToStyle, products, selectedProduct]);

  const filteredProducts = useMemo(() => {
    return products?.filter((product) => {
      return product.name
        .toLowerCase()
        .includes((searchFilter || "").toLowerCase());
    });
  }, [products, searchFilter]);

  return (
    <div className="grid grid-cols-2">
      {filteredProducts?.map((product, index) => (
        <div
          key={product._id}
          onClick={() => {
            setSelectedPart(
              product._id +
                (product?.variants && "-" + product?.variants[0].name)
            );
            eventBus.emit("onProductClick");
          }}
          className="mb-8 group cursor-pointer flex flex-col items-center"
        >
          <img
            className={`${
              selectedProduct === product._id ? "opacity-100" : "opacity-70"
            } group-hover:opacity-100 md:w-14 md:h-14 w-10 h-10 object-contain mb-4`}
            src={product.thumbnailUrl}
            alt=""
          />

          <p
            className={`mt-1 ${
              selectedProduct === product._id
                ? "opacity-100 font-bold"
                : "opacity-70"
            } group-hover:opacity-100  w-32 text-center text-sm`}
          >
            {product.name}
          </p>
        </div>
      ))}
    </div>
  );
}
