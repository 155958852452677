import React, { useMemo } from "react";
import { AFrame } from "../aFrame";
import { useProducts } from "../api";
import downArrowIcon from "../assets/icons/down-arrow.svg";
import { useAppSelector } from "../hooks/redux";
import getUrlParam from "../utils/getUrlParam";
import Parts from "./Products";

interface Props {
  aFrame: AFrame | null;
  showDetails: (partDetails: { name: string; productId: string }) => void;
  name: string;
  id: string;
  isExpanded: boolean;
  onClick: () => void;
  hideProducts: () => void;
}

export const Brand = ({
  showDetails,
  aFrame,
  name,
  id,
  isExpanded,
  hideProducts,
  onClick,
}: Props) => {
  const { data: products } = useProducts();
  const currentArea = useAppSelector((state) => state.area.selectedArea);
  const productsList = getUrlParam("products")?.split(",");
  const filteredProducts = useMemo(() => {
    return products?.filter((product) => {
      return (
        product.collection._id === id &&
        product.isVisibleOnVisualizer &&
        currentArea?.area === product.area &&
        (productsList?.includes(product._id) || !productsList)
      );
    });
  }, [currentArea?.area, id, products, productsList]);

  if (filteredProducts === undefined || filteredProducts?.length === 0) {
    return null;
  }

  return (
    <div>
      <div
        onClick={onClick}
        className="flex justify-between px-12 py-4 cursor-pointer"
      >
        <div className=" py-4 text-xl font-bold">{name}</div>
        <img
          src={downArrowIcon}
          alt=""
          className={`${
            isExpanded ? "" : "rotate-180"
          } transform transition-transform w-3`}
        />
      </div>

      <div
        className={`${
          isExpanded ? "h-auto" : "h-0"
        } overflow-hidden flex flex-col`}
      >
        <Parts
          products={filteredProducts}
          aFrame={aFrame}
          showDetails={showDetails}
          hideProducts={hideProducts}
        />
      </div>
    </div>
  );
};
