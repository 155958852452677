import { useCallback, useEffect, useState } from "react";
import SharePopUp from "../Components/SharePopUp";
import DetailsPopUp from "../Components/DetailsPopUp";
import aframe, { AFrame } from "../aFrame";
import Preview from "../Components/Preview";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { changeArea } from "../store/globalState";
import { Header } from "../Components/Header";
import eventBus from "../events";
import { Area } from "../types";

export default function Page2({
  aFrame,
  setAFrame,
}: {
  aFrame: AFrame | null;
  setAFrame: (aFrame: AFrame) => void;
}) {
  const [showSharePopUp, setShowSharePopUp] = useState(false);
  const [showDetailsPopUp, setShowDetailsPopUp] = useState(false);
  const [, setPlaceHolder] = useState<any | undefined>();
  const [partDetails, setPartDetails] = useState<
    undefined | { name: string; productId: string }
  >();

  const selectedArea = useAppSelector((state) => state.area.selectedArea);
  const currentBackground = useAppSelector(
    (state) => state.style.currentBackground
  );

  const dispatch = useAppDispatch();

  const setSelectedArea = useCallback(
    (area: Area) => dispatch(changeArea(area)),
    [dispatch]
  );

  useEffect(() => {
    setAFrame(aframe("A-frame"));
  }, [setAFrame]);

  const showDetails = useCallback(
    (partDetails: { name: string; productId: string }) => {
      setPartDetails(partDetails);
      setShowDetailsPopUp(true);
    },
    []
  );

  const setArea = useCallback(
    (area: string | Area) => {
      if (typeof area === "string") {
        const x = currentBackground?.areas?.find((a) => a.area === area);
        x && setSelectedArea(x);
      } else {
        setSelectedArea(area);
      }
      aFrame?.clearCurrentElements("all");
      eventBus.emit("areaChanged");
    },
    [aFrame, currentBackground?.areas, setSelectedArea]
  );

  return (
    <div>
      {showDetailsPopUp && (
        <DetailsPopUp
          setShowDetailsPopUp={setShowDetailsPopUp}
          partDetails={partDetails}
        />
      )}
      {showSharePopUp && <SharePopUp setShowSharePopUp={setShowSharePopUp} />}

      <div className=" flex flex-col w-screen overflow-hidden h-screen">
        <Header
          aFrame={aFrame}
          showDetails={showDetails}
          setArea={setArea}
          selectedArea={selectedArea}
        />

        <div className="flex z-0 md:flex-row flex-col   justify-center flex-grow ">
          <Preview
            setShowSharePopUp={setShowSharePopUp}
            aFrame={aFrame}
            setPlaceHolder={setPlaceHolder}
            setArea={setArea}
          />
        </div>
      </div>
    </div>
  );
}
