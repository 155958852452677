import { Image360Url } from "../types";

export const addCubeMapSky =
  (
    aScene: HTMLElement | null,
    aAssets: HTMLElement | null,
    currentElements: HTMLElement[],
    resetCamera: () => void,
    backgroundFolderUrl: string
  ) =>
  (map?: Image360Url, nz?: string, map2?: Image360Url) => {
    if (!aScene || !aAssets) {
      console.warn("aScene or aAssets is null");
      return;
    }
    //log minutes and seconds miliseconds
    const logTime = () => {
      const d = new Date();
      const h = d.getHours();
      const m = d.getMinutes();
      const s = d.getSeconds();
      const ms = d.getMilliseconds();
      const time = `${h}:${m}:${s}:${ms}`;
      console.log(time);
    };
    logTime();

    const prevAsset = document.getElementsByClassName("a-asset-sky")[0];

    // const getFolderUrl = () => {
    //   const px = prevAsset?.getElementsByTagName("img")[1]?.getAttribute("src");
    //   const folder = px?.substring(0, px.lastIndexOf("/") + 1);
    //   return folder;
    // };

    // const folder = _folder ? _folder : getFolderUrl();
    // const folder = _folder ? _folder : backgroundFolderUrl;
    // if (!folder) {
    //   console.warn("folder is null");
    //   return;
    // }
    // backgroundFolderUrl = folder;
    if (prevAsset) {
      prevAsset.parentElement?.removeChild(prevAsset);
    }

    const asset = document.createElement("a-cubemap");
    asset.setAttribute("id", "cubemap-sky");
    asset.className = "a-asset-sky";
    // asset.innerHTML = `
    //       <img class='nz' src="${nz ? nz : folder + "nz.jpg"}"  />
    //       <img src="${folder}pz.jpg" />
    //       <img src="${folder}py.jpg" />
    //       <img src="${folder}ny.jpg" />
    //       <img src="${folder}px.jpg" />
    //       <img src="${folder}nx.jpg" />
    //     `;
    asset.innerHTML = `
    <img src="${map?.px}" />
    <img src="${map?.nx}" />
    <img src="${map?.py}" />
    <img src="${map?.ny}" />
    <img src="${map?.pz}" />
    <img class='nz' src="${map?.nz}"  />
        `;
    aAssets.appendChild(asset);
    currentElements.push(asset);

    resetCamera();

    const prevBackground = document.querySelector(
      "a-entity#cubemap-background"
    );
    if (prevBackground) {
      prevBackground.parentNode?.removeChild(prevBackground);
    }

    const background = document.createElement("a-entity");
    background.setAttribute("id", "cubemap-background");
    background.setAttribute("cubemap", `folder:#cubemap-sky; edgeLength:20;`);
    aScene.appendChild(background);
  };
