import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { changePage } from "../store/globalState";
import leftArrow from "../assets/icons/left-arrow.svg";
import plusIcon from "../assets/icons/plus.svg";
import dropDownIcon from "../assets/icons/drop-down.svg";
import Areas from "./Areas";
import { AFrame } from "../aFrame";
// import { Brands } from "./Brands";
import { Area } from "../types";
import { useAreas, useProducts } from "../api";
import searchIcon from "../assets/icons/search.svg";
import Products from "./Products";
import getUrlParam from "../utils/getUrlParam";
import { Variants } from "./Variants";
import { Collections } from "./Collections";
interface Props {
  selectedArea: Area | undefined;
  setArea: (area: string | Area) => void;
  aFrame: AFrame | null;
  showDetails: (partDetails: { name: string; productId: string }) => void;
}

export const Header = ({
  selectedArea,
  setArea,
  showDetails,
  aFrame,
}: Props) => {
  const [showAreas, setShowAreas] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [search, setSearch] = useState("");
  const currentBackground = useAppSelector(
    (state) => state.style.currentBackground
  );
  const currentArea = useAppSelector((state) => state.area.selectedArea);
  const { data: areas } = useAreas();
  const { data: products } = useProducts();
  const productsList = getUrlParam("products")?.split(",");
  const dispatch = useAppDispatch();

  const hideProducts = useCallback(() => {
    setShowProducts(false);
  }, []);

  const getAreaDetail = (id: string) => {
    const area = areas?.find((area) => area._id === id);

    return area;
  };

  useEffect(() => {}, []);
  const getMainHeaderHeight = () => {
    const mainHeader = document.getElementById("header-main");

    if (mainHeader) {
      return mainHeader.clientHeight;
    }
  };
  const handleChangeStyle = () => dispatch(changePage("page1"));

  const filteredProducts = useMemo(() => {
    return products?.filter((product) => {
      return (
        product.isVisibleOnVisualizer &&
        currentArea?.area === product.area &&
        (productsList?.includes(product._id) || !productsList)
      );
    });
  }, [currentArea?.area, products, productsList]);

  return (
    <div className="z-10" id="header">
      <div
        id="header-main"
        className="flex items-center w-full border-b pb-8 pt-8 md:px-8 px-2 border-0"
      >
        <div
          className={`${
            showProducts ? "md:flex hidden" : ""
          } flex w-full cursor-pointer`}
        >
          <div
            onClick={handleChangeStyle}
            className="flex"
            id="tour-change-style"
          >
            <img
              src={leftArrow}
              className="w-5 md:ml-0 ml-2  object-contain "
              alt=""
            />
            <h3 className="md:text-xl text-sm md:ml-4 ml-2 font-bold">
              {currentBackground?.name}
            </h3>
          </div>
          <div
            onClick={() =>
              setShowAreas(
                (currentBackground?.areas.length || 0) > 1 && !showAreas
              )
            }
            className="flex ml-4"
            id="tour-change-area"
          >
            <h3 className="md:text-xl  text-sm font-bold">
              {getAreaDetail(currentArea?.area || "")?.name}
            </h3>
            {(currentBackground?.areas.length || 0) > 1 && (
              <img
                src={dropDownIcon}
                className="w-5 md:ml-2 ml-2  object-contain "
                alt=""
              />
            )}
          </div>
        </div>

        <div className="flex items-center h-8" id="tour-add-product">
          {showProducts && (
            <div className="border rounded-full  md:w-52 w-1/2 px-4 py-2 flex items-center">
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                value={search}
                className="focus:outline-none w-4/5 md:w-full"
              />
              <img
                src={searchIcon}
                className="w-5 ml-auto object-contain "
                alt=""
              />
            </div>
          )}
          <div
            onClick={() => setShowProducts(!showProducts)}
            className="ml-auto whitespace-nowrap cursor-pointer md:text-xl text-sm md:ml-4 font-bold"
          >
            Add product
          </div>

          <img
            onClick={() => setShowProducts(!showProducts)}
            src={plusIcon}
            className="w-5 mr-5 md:ml-4 ml-4 cursor-pointer object-contain "
            alt=""
          />
        </div>
      </div>
      <div className="relative ml-8 h-0 w-max overflow-visible">
        <div
          className={`${
            showAreas ? "flex" : "hidden"
          }  -bottom-0 bg-white z-50`}
        >
          <Areas selectedArea={selectedArea} setArea={setArea} />
        </div>
      </div>
      <div className="relative ml-auto h-0 w-max overflow-visible">
        <div
          style={{
            height: `calc(100vh - ${getMainHeaderHeight()}px)`,
          }}
          className={`${
            showProducts ? "" : "translate-x-full"
          } bg-white absolute flex items-center right-0 transform transition-transform`}
        >
          <Variants />
          <div className="overflow-y-auto z-0 h-full">
            <div
              className={`${
                search ? "h-auto pt-8" : "h-0"
              } overflow-hidden  flex justify-center`}
            >
              <Products
                aFrame={aFrame}
                showDetails={showDetails}
                searchFilter={search}
                products={filteredProducts || []}
                hideProducts={hideProducts}
              />
            </div>

            <div className={`${!search ? "h-auto" : "h-0"} overflow-hidden`}>
              {/* <Brands
                aFrame={aFrame}
                showDetails={showDetails}
                hideProducts={hideProducts}
              /> */}
              <Collections
                aFrame={aFrame}
                showDetails={showDetails}
                hideProducts={hideProducts}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
