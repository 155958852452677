export const addWindow = (overLay: HTMLDivElement, target: Element) => {
  const tourWindow = document.createElement("div");
  tourWindow.classList.add("tour-window", "absolute");

  const { top, left, width, height } = getWindowPositionAndSize(target);

  tourWindow.style.top = `${top}px`;
  tourWindow.style.left = `${left}px`;
  tourWindow.style.width = `${width}px`;
  tourWindow.style.height = `${height}px`;
  tourWindow.style.zIndex = "101";
  tourWindow.style.backgroundColor = "transparent";
  tourWindow.style.padding = "10px";
  tourWindow.style.border = "solid #E3000F 5px";
  tourWindow.style.boxShadow = "5px 5px 5px 99999px rgba(0,0,0,0.75)";

  overLay.appendChild(tourWindow);
};

export const getWindowPositionAndSize = (target: Element) => {
  const viewportOffset = target.getBoundingClientRect();
  const padding = 10;
  return {
    top: viewportOffset.top - padding,
    left: viewportOffset.left - padding,
    width: viewportOffset.width + 2 * padding,
    height: viewportOffset.height + 2 * padding,
  };
};
