import { useState } from "react";
import { AFrame } from "../aFrame";
import { Brand } from "./Brand";
import { addBrand, removeBrand } from "../store/globalState";
import { useAppSelector, useAppDispatch } from "../hooks/redux";
const ChildCollection = ({
  children,
  aFrame,
  showDetails,
  hideProducts,
}: {
  children: {
    id: string;
    name: string;
  }[];
  aFrame: AFrame | null;
  showDetails: (partDetails: { name: string; productId: string }) => void;
  hideProducts: () => void;
}) => {
  const [expandedBrand, setExpandedBrand] = useState<number | null>(null);
  const selectedBrands = useAppSelector((state) => state.brands.selectedBrands);
  const dispatch = useAppDispatch();

  const handleBrandClick = (index: number) => {
    if (expandedBrand === index) {
      setExpandedBrand(999);
    } else {
      setExpandedBrand(index);
    }
  };

  return (
    <div>
      {children.map(({ name, id }, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (!selectedBrands.includes(name)) {
                dispatch(addBrand(name));
              } else {
                dispatch(removeBrand(name));
              }
            }}
          >
            <Brand
              key={name}
              name={name}
              id={id}
              aFrame={aFrame}
              showDetails={showDetails}
              isExpanded={expandedBrand === index}
              onClick={() => handleBrandClick(index)}
              hideProducts={hideProducts}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChildCollection;
