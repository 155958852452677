interface ImageProps {
  imageUrl: string;
  id: string;
  position: string;
  rotation: string;
  width: string;
  height: string;
  onClick?: () => void;
  animateType?: "bounceX" | "bounceY" | "bounceZ";
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  scale?: string;
}

export const addImage =
  (
    aScene: HTMLElement | null,
    aAssets: HTMLElement | null,
    currentElements: HTMLElement[] = []
  ) =>
  (imageProps: ImageProps) => {
    if (!aScene || !aAssets) {
      console.warn("aScene or aAssets is null");
      return;
    }
    const image = getImage(aAssets, currentElements, imageProps);

    if (!image) {
      console.warn("Couldn't create image");
      return;
    }
    aScene.appendChild(image);
    return;
  };

export const getImage = (
  aAssets: HTMLElement | null,
  currentElements: HTMLElement[] = [],
  imageProps: ImageProps
) => {
  if (!aAssets) {
    console.warn("aScene or aAssets is null");
    return;
  }
  const {
    imageUrl,
    id,
    position,
    rotation,
    width,
    height,
    onClick,
    animateType,
    onMouseEnter,
    onMouseLeave,
    scale,
  } = imageProps;

  const prevAsset = document.getElementsByClassName("a-asset" + id)[0];

  if (prevAsset) {
    prevAsset.parentElement?.removeChild(prevAsset);
  }

  const asset = document.createElement("img");
  asset.setAttribute("id", id);
  asset.className = "a-asset" + id;

  asset.setAttribute("src", imageUrl);
  aAssets.appendChild(asset);
  currentElements.push(asset);

  const prevEntity = document.getElementsByClassName("a-image" + id)[0];
  if (prevEntity) {
    prevEntity.parentElement?.removeChild(prevEntity);
  }
  const aEntity = document.createElement("a-image");
  aEntity.setAttribute("src", `#${id}`);
  aEntity.setAttribute("radius", "4");
  aEntity.setAttribute("height", height || "");
  aEntity.setAttribute("width", width || "");
  aEntity.setAttribute("position", position);
  aEntity.setAttribute("rotation", rotation);
  aEntity.setAttribute("scale", scale || "1 1 1");
  aEntity.setAttribute("class", "a-image" + id + " clickable " + id);

  onClick && (aEntity.onclick = onClick);
  onMouseEnter && (aEntity.onmouseenter = onMouseEnter);
  onMouseLeave && (aEntity.onmouseleave = onMouseLeave);
  if (animateType) {
    aEntity.setAttribute("animation", getAnimation(animateType, position));
  }
  currentElements.push(aEntity);
  return aEntity;
};

const getAnimation = (
  type: "bounceX" | "bounceY" | "bounceZ",
  from: string
) => {
  const x = parseFloat(from.split(" ")[0]);
  const y = parseFloat(from.split(" ")[1]);
  const z = parseFloat(from.split(" ")[2]);

  switch (type) {
    case "bounceY":
      return `property: position; from: ${x} ${y - 0.25} ${z}; to: ${x} ${
        y + 0.25
      } ${z}; dir:alternate; dur: 500; easing: linear; loop: true`;

    case "bounceX":
      return `property: position; from: ${x - 0.15} ${y} ${z}; to: ${
        x + 0.15
      } ${y} ${z}; dir:alternate; dur: 1000; easing: linear; loop: true`;

    case "bounceZ":
      return `property: position; from: ${x} ${y} ${z - 0.15}; to: ${x} ${y} ${
        z + 0.15
      }; dir:alternate; dur: 1000; easing: linear; loop: true`;

    default:
      return "";
  }
};
