import React, { useEffect, useState } from "react";
import closeIcon from "../assets/icons/close.svg";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";

export default function SharePopUp({
  setShowSharePopUp,
}: {
  setShowSharePopUp: (show: boolean) => void;
}) {
  const [scrollY, setScrollY] = useState(0);
  const [image, setImage] = useState(null);
  useEffect(() => {
    window.onscroll = () => {
      setScrollY(window.scrollY);
    };
    return () => {
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const ele: any = document.querySelector("a-scene");
      const img = ele?.components.screenshot
        .getCanvas("perspective")
        .toDataURL("image/png");

      setImage(img);
    }, 300);
  }, []);

  return (
    <div
      style={{ top: (window.scrollY || scrollY) + "px" }}
      className="absolute w-full h-screen p-4 z-50  flex items-center justify-center"
    >
      <div className="w-full h-full bg-black z-10 opacity-40 absolute"></div>
      <div className="bg-white md:w-2/5 w-full z-10 h-2/3 md:h-auto flex flex-col items-center relative">
        <img
          style={{
            top: "16px",
            right: "16px",
          }}
          src={closeIcon}
          className="absolute cursor-pointer bg-white rounded-full"
          alt=""
          onClick={() => setShowSharePopUp(false)}
        />
        {image ? (
          <img
            style={{ height: "382px" }}
            src={image || ""}
            alt=""
            className=" object-fill w-full"
          />
        ) : (
          <div
            style={{ height: "382px" }}
            className=" flex items-center justify-center font-bold text-gray-600 "
          >
            snapshot loading...
          </div>
        )}
        <p className=" font-bold md:text-3xl text-xl md:m-8 m-3">
          Center Hole Basin Mixer
        </p>

        <div className="flex md:mt-4 mb-8 opacity-30 pointer-events-none cursor-not-allowed">
          <FacebookShareButton
            url={"https://example.com"}
            quote={"Quote"}
            hashtag="#ashirvad"
            className="mx-2 "
          >
            <FacebookIcon size={36} />
          </FacebookShareButton>
          <TwitterShareButton
            url={"https://example.com"}
            title={"Quote"}
            via={"ashirvad"}
            hashtags={["ashirvad"]}
            className="mx-2"
          >
            <TwitterIcon size={36} />
          </TwitterShareButton>
          <EmailShareButton url={"https://example.com"} className="mx-2">
            <EmailIcon size={36} />
          </EmailShareButton>
        </div>

        {/* <button className="bg-black text-white font-bold px-32 py-4 md:my-12 my-4">
          COPY LINK
        </button> */}
      </div>
    </div>
  );
}
