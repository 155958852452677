import { removeOverLay } from "./removeOverLay";

export const addOverLay = () => {
  removeOverLay();
  const overLay = document.createElement("div");
  overLay.classList.add(
    "tour-overlay",
    "flex",
    "justify-center",
    "overflow-hidden"
  );
  overLay.style.top = "0px";
  overLay.style.left = "0px";
  overLay.style.width = "100%";
  overLay.style.height = "100%";
  overLay.style.backgroundColor = "transparent";
  overLay.style.position = "absolute";
  overLay.style.zIndex = "100";

  document.body.appendChild(overLay);
  return overLay;
};
