import React from "react";
import { useAreas } from "../api";
import { useAppSelector } from "../hooks/redux";
import { Area } from "../types";

interface Props {
  selectedArea: Area | undefined;
  setArea: (area: string | Area) => void;
}

export default function Location({ selectedArea, setArea }: Props) {
  const currentBackground = useAppSelector(
    (state) => state.style.currentBackground
  );
  const { data: areas } = useAreas();

  const getAreaDetail = (id: string) => {
    const area = areas?.find((area) => area._id === id);

    return area;
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      {currentBackground?.areas.map((item: any, index) => (
        <div
          key={item.area}
          onClick={() => {
            setArea(item);
          }}
          className="m-4 group cursor-pointer flex flex-col items-center"
        >
          <img
            src={getAreaDetail(item.area || "")?.thumbnailUrl}
            className={` mx-2 stroke-current fill-current ${
              selectedArea?.area === item.area ? "opacity-100" : "opacity-50"
            } group-hover:text-black md:w-20 md:h-8 w-16 h-4`}
            alt=""
          />
          <p
            className={`font-bold mt-2 ${
              selectedArea?.area === item.area ? "opacity-100" : "opacity-50"
            } group-hover:text-black md:text-sm text-xs`}
          >
            {getAreaDetail(item.area || "")?.name.toUpperCase()}
          </p>
        </div>
      ))}
    </div>
  );
}
