import React, { useCallback, useEffect, useState } from "react";
import { useProducts } from "../api";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { addPart } from "../store/globalState";
import closeGray from "../assets/icons/close-gray.svg";
import eventBus from "../events";
import checkIcon from "../assets/icons/check.svg";

export const Variants = () => {
  const [showVariants, setShowVariants] = useState(false);
  const { data: products } = useProducts();
  const dispatch = useAppDispatch();
  const currentArea = useAppSelector((state) => state.area.selectedArea);
  const selectedProducts = useAppSelector(
    (state) => state.products.productsAdded
  );
  const selectedProductId = selectedProducts[currentArea?.area || ""];
  const selectedProduct = products?.find(
    ({ _id }) => _id === selectedProductId?.split("-")[0]
  );
  useEffect(() => {
    const removeListener = eventBus.on(["onProductClick"], () =>
      setShowVariants(true)
    );
    return () => {
      removeListener();
    };
  }, [selectedProduct?.variants?.length]);

  const setSelectedPart = useCallback(
    (partId: string | undefined) => {
      currentArea && dispatch(addPart({ [currentArea.area]: partId }));
    },
    [dispatch, currentArea]
  );

  return (
    <>
      {(selectedProduct?.variants?.length || 0) > 1 && showVariants && (
        <div className=" mt-auto bg-white shadow-lg m-4 z-10 absolute flex-col  flex  p-4">
          <div className="flex mb-2">
            <h1 className="font-bold text-xl cursor-pointer">Colors</h1>
            <img
              onClick={() => setShowVariants(!showVariants)}
              src={closeGray}
              className="w-8 ml-auto cursor-pointer"
              alt=""
            />
          </div>

          <div className="flex-wrap flex">
            {selectedProduct?.variants?.map((variant) => (
              <div
                key={variant.name}
                onClick={() =>
                  setSelectedPart(selectedProduct._id + "-" + variant.name)
                }
                className="w-16 h-16 flex items-center justify-center rounded-full m-1 cursor-pointer"
                style={{ backgroundColor: variant.hexCode }}
              >
                {selectedProductId?.split("-")[1] === variant.name && (
                  <img src={checkIcon} alt="" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
