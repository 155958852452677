import { useState } from "react";
import { AFrame } from "./aFrame";
import "./App.css";
import { useAppSelector } from "./hooks/redux";
import Page1 from "./Pages/Page1";
import Page2 from "./Pages/Page2";

function App() {
  const [aFrame, setAframe] = useState<AFrame | null>(null);
  const currentPage = useAppSelector((state) => state.page.currentPage);
  const currentBackground = useAppSelector(
    (state) => state.style.currentBackground
  );

  switch (currentPage) {
    case "page1":
      return <Page1 aFrame={aFrame} />;

    case "page2":
      return currentBackground ? (
        <Page2 aFrame={aFrame} setAFrame={setAframe} />
      ) : null;

    default:
      return null;
  }
}

export default App;
