import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Area, AreaDetail, Background } from "../types";

const pageSlice = createSlice({
  name: "page",
  initialState: {
    currentPage: "page1",
  },
  reducers: {
    changePage(state, action: PayloadAction<"page1" | "page2">) {
      state.currentPage = action.payload;
    },
  },
});

const dataSlice = createSlice({
  name: "data",
  initialState: {
    areas: [] as Area[],
    backgrounds: [] as Background[],
  },
  reducers: {
    setAreas(state, action: PayloadAction<Area[]>) {
      state.areas = action.payload;
    },
    setBackgrounds(state, action: PayloadAction<Background[]>) {
      state.backgrounds = action.payload;
    },
  },
});

const backgroundSlice = createSlice({
  name: "background",
  initialState: {
    currentBackground: undefined as Background | undefined,
  },
  reducers: {
    changeBackground(state, action: PayloadAction<Background | undefined>) {
      state.currentBackground = action.payload;
    },
  },
});

const brandsSlice = createSlice({
  name: "brands",
  initialState: {
    selectedBrands: [] as string[],
  },
  reducers: {
    addBrand(state, action: PayloadAction<string>) {
      state.selectedBrands.push(action.payload);
    },
    removeBrand(state, action: PayloadAction<string>) {
      state.selectedBrands = state.selectedBrands.filter(
        (brand) => brand !== action.payload
      );
    },
  },
});

interface productsState {
  productsAdded: { [key: string]: string | undefined };
}

const initialParts: productsState = {
  productsAdded: {},
};

const productsSlice = createSlice({
  name: "products",
  initialState: initialParts,
  reducers: {
    addPart(
      state,
      action: PayloadAction<{ [key: string]: string | undefined }>
    ) {
      state.productsAdded = { ...state.productsAdded, ...action.payload };
    },
  },
});

const areaSlice = createSlice({
  name: "area",
  initialState: {
    selectedArea: undefined as Area | undefined,
    areas: [] as AreaDetail[],
  },
  reducers: {
    changeArea(state, action: PayloadAction<Area>) {
      state.selectedArea = action.payload;
    },
    setAreas(state, action: PayloadAction<AreaDetail[]>) {
      state.areas = action.payload;
    },
  },
});

const miscSlice = createSlice({
  name: "misc",
  initialState: {
    autoAddedProductsToArea: {} as { [key: string]: boolean },
  },
  reducers: {
    toggleAutoAdd(state, action: PayloadAction<string>) {
      state.autoAddedProductsToArea[action.payload] =
        state.autoAddedProductsToArea[action.payload] === undefined
          ? true
          : !state.autoAddedProductsToArea[action.payload];
    },
    setAutoAddedToTrue(state, action: PayloadAction<string>) {
      state.autoAddedProductsToArea[action.payload] = true;
    },
    setAutoAddedToFalse(state, action: PayloadAction<string>) {
      state.autoAddedProductsToArea[action.payload] = false;
    },
  },
});

export const { toggleAutoAdd, setAutoAddedToTrue, setAutoAddedToFalse } =
  miscSlice.actions;
export const miscReducer = miscSlice.reducer;

export const { changePage } = pageSlice.actions;
export const pageReducer = pageSlice.reducer;

export const { setBackgrounds } = dataSlice.actions;
export const dataReducer = dataSlice.reducer;

export const { changeBackground } = backgroundSlice.actions;
export const stylesReducer = backgroundSlice.reducer;

export const { addBrand, removeBrand } = brandsSlice.actions;
export const brandsReducer = brandsSlice.reducer;

export const { addPart } = productsSlice.actions;
export const productsReducer = productsSlice.reducer;

export const { changeArea, setAreas } = areaSlice.actions;
export const areaReducer = areaSlice.reducer;
