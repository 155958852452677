import { parseLocation } from "../utils/parseLocation";
import { getImage } from "./addImage";

export const addHoverMenu =
  (
    aScene: HTMLElement | null,
    aAssets: HTMLElement | null,
    currentElements: HTMLElement[] = []
  ) =>
  (
    options: {
      onClick?: () => void;
      text?: string;
      imageUrl?: string;
    }[],
    id: string,
    position: string,
    rotation: string,
    finalPosition: string
  ) => {
    if (!aScene || !aAssets) {
      console.warn("aScene or aAssets is null");
      return;
    }

    let hideMenuTimeoutHandle: NodeJS.Timeout | null = null;

    const prevMenu = document.querySelector(`#${id}`);
    if (prevMenu) {
      prevMenu.remove();
    }

    const aEntityContainer = document.createElement("a-entity");
    aEntityContainer.setAttribute("id", id);

    const { x, y, z } = parseLocation(position);

    aEntityContainer.setAttribute("position", `${x} ${y} ${z}`);
    aEntityContainer.setAttribute("rotation", rotation);
    aEntityContainer.setAttribute("scale", "0 0 0");
    aEntityContainer.setAttribute("width", "4");
    aEntityContainer.setAttribute("geometry", "primitive: plane; width: 4");
    aEntityContainer.setAttribute("material", "opacity: 0");
    currentElements.push(aEntityContainer);
    aScene.appendChild(aEntityContainer);

    options &&
      options.forEach((option, index) => {
        // const label = getLabel(option.text, id, `0 ${index} 0`);
        const image = getImage(aAssets, currentElements, {
          imageUrl: option.imageUrl || "",
          id: `${id}-${index}`,
          position: `${index * 2} 0 0`,
          rotation: "",
          width: "2",
          height: "1",
          onClick: option.onClick,
        });

        image && image.addEventListener("click", () => hideMenu());

        image &&
          image.addEventListener("mouseenter", () => {
            if (hideMenuTimeoutHandle) clearTimeout(hideMenuTimeoutHandle);
          });

        image &&
          image.addEventListener("mouseleave", () => {
            hideMenu();
          });

        image && aEntityContainer.appendChild(image);
      });

    const animationDuration = 300;

    const getMoveAnimation = (from: string, to: string) =>
      `property: position; from: ${from}; to: ${to}; dir:alternate; dur: ${animationDuration}; easing: linear; `;
    const getScaleAnimation = (from: string, to: string) =>
      `property: scale; from: ${from}; to: ${to}; isRawProperty:true; dur: ${animationDuration};`;

    const showMenu = () => {
      if (hideMenuTimeoutHandle) clearTimeout(hideMenuTimeoutHandle);

      aEntityContainer.setAttribute(
        "animation",
        getMoveAnimation(`${x} ${y} ${z}`, finalPosition)
      );
      aEntityContainer.setAttribute(
        "animation__expand",
        getScaleAnimation(`0 0 0`, `1 1 1`)
      );
    };

    const hideMenu = () => {
      hideMenuTimeoutHandle = setTimeout(() => {
        if (!aEntityContainer) return;
        aEntityContainer.setAttribute(
          "animation",
          getMoveAnimation(finalPosition, `${x} ${y} ${z}`)
        );

        aEntityContainer.setAttribute(
          "animation__expand",
          getScaleAnimation(`1 1 1`, `0 0 0`)
        );
      }, 2000);
    };

    return {
      showMenu,
      hideMenu,
    };
  };
