// import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { Background } from "../types";
import StyleCard from "../Components/StyleCard";

import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { changeArea, changePage, changeBackground } from "../store/globalState";
import { AFrame } from "../aFrame";
import { init } from "../tour";
import { useBackgrounds } from "../api";
import { LoadingScreen } from "../Components/LoadingScreen";

export default function Page1({ aFrame }: { aFrame: AFrame | null }) {
  const selectedArea = useAppSelector((state) => state.area.selectedArea);
  const dispatch = useAppDispatch();

  const { data: backgrounds, isLoading, error } = useBackgrounds();

  const setBackground = useCallback(
    (background: Background) => {
      console.log("setBackground", background);

      aFrame?.setCameraPosition({ x: 0, y: 0, z: 0 });

      dispatch(changeBackground(background));
      const area = background?.areas?.find(
        (aB) => aB.area === selectedArea?.area
      );
      dispatch(changeArea(area || background?.areas[0]));
      dispatch(changePage("page2"));
    },
    [aFrame, dispatch, selectedArea?.area]
  );

  const handleChangeStyle = useCallback(() => {
    dispatch(changePage("page1"));
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      init([
        {
          onNext: () => backgrounds && setBackground(backgrounds[0]),
        },
        { onPrev: handleChangeStyle },
      ]);
    }
  }, [
    backgrounds,
    dispatch,
    error,
    handleChangeStyle,
    isLoading,
    selectedArea,
    setBackground,
  ]);

  const styles = (
    <div className="flex items-center justify-center md:px-20 flex-grow flex-col">
      <h1 className="md:text-4xl text-xl text-center font-bold mb-10 md:mt-10">
        Welcome to Ashirvad Bathroom Experiences
      </h1>
      <h3 className="md:mb-8 md:text-xl text-base font-bold">
        Please pick a bathroom style to start
      </h3>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
        {backgrounds
          ?.filter((b) => b.isVisibleOnVisualizer)
          .map((item) => (
            <StyleCard
              onClick={() => setBackground(item)}
              key={item._id}
              imageUrl={item.thumbnailUrl || ""}
              name={item.name}
            />
          ))}
      </div>
    </div>
  );

  return (
    <div className="App flex flex-col items-center py-4 md:p-4 h-screen">
      <LoadingScreen
        isLoading={isLoading}
        error={error ? "Something went wrong!" : undefined}
      />
      <div style={{ minHeight: "2.5rem" }} className="h-10 md:mb-0 mb-8 w-36">
        {true && (
          <img
            src="/logo.png"
            className="w-full md:mb-0 mb-8 object-contain flex-grow-0"
            alt=""
          />
        )}
      </div>

      {styles}
    </div>
  );
}
