export const clearCurrentElements =
  (currentElements: HTMLElement[] = []) =>
  (type: "navigation" | "other" | "all") => {
    const filteredParts = currentElements.filter((ele) => {
      switch (type) {
        case "other":
          if (
            !ele.className.includes("navigation") &&
            !ele.className.includes("a-asset-sky")
          ) {
            ele.parentElement?.removeChild(ele);
          }

          return ele.className.includes("navigation");

        case "all":
          ele.parentElement?.removeChild(ele);
          return false;

        case "navigation":
          ele.className.includes("navigation") &&
            ele.parentElement?.removeChild(ele);

          return !ele.className.includes("navigation");

        default:
          return false;
      }
    });
    return filteredParts;
  };
