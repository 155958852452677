type eventType =
  | "styleChanged"
  | "partAdded"
  | "zoomOut"
  | "zoomIn"
  | "areaChanged"
  | "onProductClick";

const eventBus = {
  on(event: eventType[], callback: () => any) {
    const removers = event.map((e) => {
      const handle = () => callback();
      document.addEventListener(e, handle);
      return () => document.removeEventListener(e, handle);
    });
    return () => removers.forEach((r) => r());
  },
  emit(event: eventType) {
    document.dispatchEvent(new CustomEvent(event));
  },
  off(event: any, callback: (this: Document, ev: any) => any) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
