import React, { useState } from "react";

type Props = {
  imageUrl: string;
  name: string;
  onClick: () => void;
};

export default function StyleCard({ imageUrl, name, onClick }: Props) {
  const [isMouseHovering, setIsMouseHovering] = useState(false);

  return (
    <div
      id="tour-style"
      className=" cursor-pointer w-80 h-50 md:w-72 md:h-41 m-2 overflow-hidden relative flex items-center justify-center"
      style={{
        width: "18rem",
        height: "calc(18rem / 1.6)",
      }}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
      onClick={onClick}
    >
      <div className=" absolute ">
        {!isMouseHovering && (
          <div
            className="absolute"
            style={{
              width: "22rem",
              height: "13.75rem",
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
            }}
          />
        )}
        <img
          style={{
            width: isMouseHovering ? "28rem" : "22rem",
            height: isMouseHovering ? "17.5rem" : "13.75rem",
            objectFit: "cover",
          }}
          src={imageUrl}
          alt=""
        />
      </div>
      <p
        className={`${
          isMouseHovering ? "bg-white text-black" : "text-white"
        } font-bold absolute bottom-0 left-0 px-3 py-3`}
      >
        {name}
      </p>
    </div>
  );
}
