import { getWindowPositionAndSize } from "./addWindow";
import { steps } from "./steps";

export const addTourGuide = (
  overLay: HTMLDivElement,
  index: number,
  showStep: (index: number) => void,
  actions: { onNext?: Function; onPrev?: Function }[],
  target: Element
) => {
  const step = steps[index];
  const tourGuide = document.createElement("div");

  tourGuide.classList.add("tour-guide", "p-4", "absolute");
  tourGuide.style.zIndex = "102";
  tourGuide.style.width = "28rem";
  tourGuide.style.backgroundColor = "#E3000F";
  const title = document.createElement("div");
  title.classList.add(
    "tour-guide-title",
    "font-bold",
    "md:text-2xl",
    "text-lg",
    "text-white",
    "mb-8"
  );
  title.innerText = step.title;
  tourGuide.appendChild(title);

  const text = document.createElement("div");
  text.classList.add(
    "tour-guide-text",
    "md:text-lg",
    "text-base",
    "text-white"
  );
  text.innerText = step.text;
  tourGuide.appendChild(text);

  const footer = document.createElement("div");
  footer.classList.add(
    "tour-guide-footer",
    "flex",
    "w-full",
    "mt-8",
    "text-white",

    "text-base"
  );

  tourGuide.appendChild(footer);

  const closeButton = document.createElement("button");
  closeButton.classList.add("tour-guide-close", "font-bold", "opacity-60");
  closeButton.innerText = "Skip walkthrough";
  closeButton.addEventListener("click", () => {
    overLay.remove();
  });
  footer.appendChild(closeButton);

  const prevButton = document.createElement("button");
  prevButton.classList.add(
    "tour-guide-prev",
    "font-bold",
    "ml-auto",
    "mr-4",
    index === 0 ? "opacity-30" : "opacity-100"
  );
  prevButton.innerText = "Prev";
  prevButton.addEventListener("click", () => {
    if (actions[index]) {
      if (actions[index]?.onPrev) {
        const a = actions[index]?.onPrev;
        a && a();
      }
    }
    showStep(index - 1);
  });
  footer.appendChild(prevButton);

  const nextButton = document.createElement("button");
  nextButton.classList.add("tour-guide-next", "font-bold");
  nextButton.innerText = index === steps.length - 1 ? "Close" : "Next";
  nextButton.addEventListener("click", () => {
    if (actions[index]) {
      if (actions[index]?.onNext) {
        const a = actions[index]?.onNext;
        a && a();
      }
    }

    showStep(index + 1);
  });
  footer.appendChild(nextButton);

  overLay.appendChild(tourGuide);

  rePositionTourGuide(tourGuide, target);
};

const rePositionTourGuide = (tourGuide: HTMLDivElement, target: Element) => {
  //detect if on mobile
  const isMobile = window.innerWidth < 768;
  if (isMobile) {
    tourGuide.style.width = "100%";
    tourGuide.style.bottom = "0px";
    return;
  }

  //position the tour guide based on what quadrant the tour window lays in
  const tourWindow = getWindowPositionAndSize(target);

  const coOrdinates = {
    y: tourWindow.top + tourWindow.height / 2,
    x: tourWindow.left + tourWindow.width / 2,
  };

  const offsetY = 20;
  const offsetX = 10;
  const tourGuideCoOrdinates = {
    x:
      coOrdinates.x < window.innerWidth / 2
        ? coOrdinates.x + tourWindow.width / 2 + offsetX
        : coOrdinates.x -
          tourWindow.width * 0.5 -
          tourGuide.offsetWidth -
          offsetX,
    y:
      coOrdinates.y < window.innerHeight / 2
        ? coOrdinates.y + tourWindow.height / 2 - offsetY
        : coOrdinates.y -
          tourWindow.height * 0.5 -
          tourGuide.offsetHeight +
          offsetY,
  };
  tourGuide.style.top = `${tourGuideCoOrdinates.y}px`;
  tourGuide.style.left = `${tourGuideCoOrdinates.x}px`;
};
