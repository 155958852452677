export const setCameraPosition =
  (aCamera: HTMLElement | null) =>
  ({ x, y, z }: { x: number; y: number; z: number }) => {
    if (!aCamera) {
      console.warn("setCameraPosition: no camera");
      return;
    }
    if (!(aCamera as any).components["custom-look-controls"]) return;
    if (!(aCamera as any).components["custom-look-controls"].yawObject) return;

    const positionStart: any = aCamera.getAttribute("position");

    aCamera.setAttribute(
      `animation__movecamera`,
      `property: position; from: ${positionStart.x} ${positionStart.y} ${positionStart.z};  to: ${x} ${y} ${z}; dur: 500; easing: linear; loop: false`
    );
  };
