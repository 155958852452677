import { useQuery } from "react-query";
import {
  Category,
  Product,
  Collection,
  Background,
  AreaDetail,
} from "../types";

const CreateQueryHook = <T>(endpoint: string) => {
  const { data, isLoading, error } = useQuery<T>(endpoint, () =>
    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint)
      .then((res) => res.json())
      .then((res) => res._items)
  );

  return {
    data,
    isLoading,
    error,
  };
};

export const useProducts = () => {
  const embed = encodeURIComponent(
    JSON.stringify({ category: 1, collection: 1 })
  );
  return CreateQueryHook<Product[]>(
    "content/products?application=visualizer&max_results=1000&&embedded=" +
      embed
  );
};

export const useCategories = () => {
  return CreateQueryHook<Category[]>(
    "content/categories?application=visualizer&sort=" +
      encodeURI('[("sortOrder", 1)]')
  );
};

export const useCollections = () => {
  return CreateQueryHook<Collection[]>(
    "content/collections?application=visualizer&sort=" +
      encodeURI('[("sortOrder", 1)]')
  );
};

export const useBackgrounds = () => {
  return CreateQueryHook<Background[]>(
    "content/visualizations?application=visualizer"
  );
};

export const useAreas = () => {
  return CreateQueryHook<AreaDetail[]>("content/areas?application=visualizer");
};
