import { addCubeMapSky } from "./addCubeMapSky";
import { addHoverMenu } from "./addHoverMenu";
import { addImage } from "./addImage";
import { addSky } from "./addSky";
import { addText } from "./addText";
import { clearCurrentElements } from "./clearCurrentElements";
import { removeImage } from "./removeImage";
import { removeSky } from "./removeSky";
import { resetCamera } from "./resetCamera";
import { setCameraPosition } from "./setCameraPosition";

const aframe = (id: string) => {
  let aScene: HTMLElement | null = null;
  let aAssets: HTMLElement | null = null;
  let image: HTMLImageElement | null = null;
  let aSky: HTMLElement | null = null;
  let aCamera: HTMLElement | null = null;
  let currentElements: HTMLElement[] = [];
  let backgroundFolderUrl: string = "";

  const init = (id: string) => {
    aScene = document.createElement("a-scene");
    aScene.setAttribute(
      "renderer",
      `
    maxCanvasWidth: 500;
    maxCanvasHeight: 500;`
    );
    aScene.setAttribute("vr-mode-ui", "enabled: false");
    aScene.setAttribute("cursor", "rayOrigin: mouse");
    aScene.setAttribute("embedded", "true");
    aScene.setAttribute("foo", "");
    aScene.setAttribute("raycaster", "objects: .clickable");
    aScene.setAttribute("debug", "true");
    aScene.style.width = "100%";
    aScene.style.height = "100%";

    aAssets = document.createElement("a-assets");

    aScene.appendChild(aAssets);

    const aEntity2 = document.createElement("a-entity");
    aEntity2.setAttribute("position", "0 0 0");
    aEntity2.setAttribute("rotation", "0 0 0");
    aCamera = document.createElement("a-entity");
    aCamera.setAttribute("camera", "active: true; fov: 60");
    aCamera.setAttribute("custom-look-controls", "disablePitch:true");
    aCamera.setAttribute("viewable", "maxyaw:20;maxpitch:60");

    aEntity2.appendChild(aCamera);
    aScene.appendChild(aEntity2);

    const light = document.createElement("a-entity");
    light.setAttribute("light", "color: #BBB; type: ambient; intensity: 1.32");
    light.setAttribute("position", "0 0 0");
    aScene.appendChild(light);

    const parentEle = document.getElementById(id);
    if (parentEle) {
      parentEle.appendChild(aScene);
    } else {
      console.error("parent element not found");
    }
  };

  init(id);

  return {
    addImage: addImage(aScene, aAssets, currentElements),
    addHoverMenu: addHoverMenu(aScene, aAssets, currentElements),
    addText: addText(aScene, aAssets, currentElements),
    removeImage,
    addCubeMapSky: addCubeMapSky(
      aScene,
      aAssets,
      currentElements,
      resetCamera(aCamera),
      backgroundFolderUrl
    ),
    resetCamera: resetCamera(aCamera),
    setCameraPosition: setCameraPosition(aCamera),
    clearCurrentElements: (type: "navigation" | "other" | "all") =>
      clearCurrentElements(currentElements)(type),
    updateBackground: (imageUrl: string, onLoad: () => void) => {
      removeSky(aSky, aScene);
      addSky(aScene, aAssets, image, aSky)(imageUrl, onLoad);
      resetCamera(aCamera)();
      clearCurrentElements(currentElements)("all");
    },
  };
};

type _AFrame = ReturnType<typeof aframe>;
export interface AFrame extends _AFrame {}

export default aframe;
