export const addSky =
  (
    aScene: HTMLElement | null,
    aAssets: HTMLElement | null,
    image: HTMLImageElement | null,
    aSky: HTMLElement | null
  ) =>
  (imageUrl: string, onLoad: () => void) => {
    if (!aScene || !aAssets) {
      console.warn("aScene or aAssets is null");
      return;
    }
    const prevSkyAsset = aAssets?.querySelector("img#sky");
    if (prevSkyAsset) {
      aAssets.removeChild(prevSkyAsset);
    }

    image = new Image();
    image.src = imageUrl;
    image.id = "sky";
    image.crossOrigin = "anonymous";
    onLoad && (image.onload = onLoad);

    aAssets.appendChild(image);

    aSky = document.createElement("a-sky");
    aSky.setAttribute("src", "#sky");
    aSky.setAttribute("radius", "10");
    aSky.setAttribute("id", "image-360");
    aSky.setAttribute(
      "animation__fade",
      "property: components.material.material.color; type: color; from: #FFF; to: #000; dur: 300; startEvents: fade"
    );
    aSky.setAttribute(
      "animation__fadeback",
      "property: components.material.material.color; type: color; from: #000; to: #FFF; dur: 300; startEvents: animationcomplete__fade"
    );

    aScene.appendChild(aSky);
  };
