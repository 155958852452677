export const removeImage = (id: string) => {
  const prevAsset = document.getElementsByClassName("a-asset" + id)[0];

  if (prevAsset) {
    prevAsset.parentElement?.removeChild(prevAsset);
  }

  const prevEntity = document.getElementsByClassName("a-image" + id)[0];
  if (prevEntity) {
    prevEntity.parentElement?.removeChild(prevEntity);
  }
};
