import React, { useEffect, useState } from "react";
import closeIcon from "../assets/icons/close-gray.svg";

type Props = {
  setShowDetailsPopUp: (show: boolean) => void;
  partDetails: { name: string; productId: string } | undefined;
};

const capitalizeEachWord = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default function DetailsPopUp({
  setShowDetailsPopUp,
  partDetails,
}: Props) {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setScrollY(window.scrollY);
    };
    return () => {
      window.onscroll = null;
    };
  }, []);

  return (
    <div
      style={{ top: (window.scrollY || scrollY) + "px" }}
      className="absolute w-full h-full p-8 z-50  flex items-center justify-center"
    >
      <div className="w-full h-full bg-black z-10 opacity-40 absolute"></div>
      <div className="bg-white p-2 md:w-2/5 w-full h-5/6  z-10 flex flex-col items-center relative">
        <img
          style={{
            left: "16px",
            top: "16px",
          }}
          src={closeIcon}
          className="absolute cursor-pointer z-10"
          alt=""
          onClick={() => setShowDetailsPopUp(false)}
        />

        <div
          className="overflow-hidden  w-full h-full   object-contain relative flex justify-center"
          id="3d-viewer bg-white"
        >
          <iframe
            id="the-iframe"
            className="threedee-container absolute w-full h-full"
            title="3D Viewer"
            src={`https://helloviewer.io/?id=${partDetails?.productId}&transparent=true`}
            allowFullScreen={true}
            frameBorder="0"
          />
          <p className=" font-bold  z-50 absolute bottom-10 ml-auto">
            {partDetails && capitalizeEachWord(partDetails.name)}
          </p>
        </div>
      </div>
    </div>
  );
}
