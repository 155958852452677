import React, { useState } from "react";
import { AFrame } from "../aFrame";
import brandsData from "./brandsData.json";
import downArrowIcon from "../assets/icons/down-arrow.svg";
import ChildCollection from "./ChildCollection";

interface Props {
  aFrame: AFrame | null;
  showDetails: (partDetails: { name: string; productId: string }) => void;
  hideProducts: () => void;
}

export const Collections = ({ aFrame, showDetails, hideProducts }: Props) => {
  const [selectedBrandName, setSelectedBrandName] = useState("");

  return (
    <div className=" pb-16">
      {brandsData?.map((brand, index) => (
        <div key={brand.id}>
          <div>
            <div
              className="flex px-8 my-4 cursor-pointer"
              onClick={() => {
                if (selectedBrandName === brand.name) {
                  setSelectedBrandName("");
                } else {
                  setSelectedBrandName(brand.name);
                }
              }}
            >
              <div className=" py-4 md:w-80 w-64 md:text-2xl text-xl font-bold">
                {brand.name}
              </div>
              <img
                src={downArrowIcon}
                alt=""
                className={`${
                  selectedBrandName === brand.name ? "" : "rotate-180"
                } transform transition-transform`}
              />
            </div>

            <div>
              {brand.children && selectedBrandName === brand.name && (
                <ChildCollection
                  children={brand.children}
                  aFrame={aFrame}
                  showDetails={showDetails}
                  hideProducts={hideProducts}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
