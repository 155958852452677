export const resetCamera =
  (aCamera: HTMLElement | null) => (animate?: boolean) => {
    if (!aCamera) {
      console.warn("aCamera is null");
      return;
    }
    if (!(aCamera as any).components["custom-look-controls"]) return;
    if (!(aCamera as any).components["custom-look-controls"].yawObject) return;

    if (animate) {
      const rotationStart: any = aCamera.getAttribute("rotation");

      aCamera.setAttribute(
        `animation__reset`,
        `property: rotation; from: 0 ${
          rotationStart.y
        } 0;  to: ${0} ${0} ${0}; dur: 500; easing: linear; loop: false`
      );

      (aCamera as any).components[
        "custom-look-controls"
      ].yawObject.rotation.y = 0;

      return new Promise((resolve) => {
        if (rotationStart.y === 0) {
          resolve(null);
        }
        setTimeout(() => {
          resolve(null);
        }, 600);
      });
    }

    (aCamera as any).components[
      "custom-look-controls"
    ].yawObject.rotation.y = 0;
  };
