export const steps = [
  {
    title: "Pick a style to begin customizing your bathroom.",
    text: "Pick a style to begin customizing your bathroom",
    selector: "#tour-style",
  },
  {
    title: "Go Back to the previous screen from here",
    text: "You can go back to the previous screen and pick any style as per your requirement",
    selector: "#tour-change-style",
  },
  {
    title: "Pick area to customize",
    text: "Pick between the bathroom or basin area and choose products for them. You can also use the guides on screen to navigate to the area.",
    selector: "#tour-change-area",
  },
  {
    title: "Add products to the selected area",
    text: "After picking the area you can pick the products from here. Just click on the “+” button and pick products from our collections.",
    selector: "#tour-add-product",
  },
  {
    title: "Share your bathroom selection with family and friends",
    text: "Share your bathroom selection with family and friends and get their opinion on your picks. Just click the share button on the right.",
    selector: "#tour-share",
  },
];
