import React from "react";
import ReactDOM from "react-dom";

export const LoadingScreen = ({
  isLoading,
  error,
}: {
  isLoading: boolean;
  error?: string;
}) => {
  if (!isLoading && !error) {
    return null;
  }
  return ReactDOM.createPortal(
    <div
      style={{ backgroundColor: "rgb(0,0,0,0.9)" }}
      className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center"
    >
      <h1 className="font-bold bg-white p-4 rounded-xl">
        {error ? error : "Loading Data..."}
      </h1>
    </div>,
    document.body
  );
};
