import { useEffect, useState } from "react";
import { AFrame } from "../aFrame";
import { useAreas } from "../api";
import maximizeIcon from "../assets/icons/maximize.svg";
import minimizeIcon from "../assets/icons/minimize.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/share.svg";
import { useAppSelector } from "../hooks/redux";
import { Area, PositionOrPoint } from "../types";
import { ZoomToast } from "./ZoomToast";

type Props = {
  setShowSharePopUp: (show: boolean) => void;
  aFrame: AFrame | null;
  setPlaceHolder: (placeHolder: PositionOrPoint | undefined) => void;
  setArea: (area: string | Area) => void;
};

export default function Preview({
  setShowSharePopUp,
  aFrame,
  setPlaceHolder,
  setArea,
}: Props) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const currentBackground = useAppSelector(
    (state) => state.style.currentBackground
  );
  const selectedArea = useAppSelector((state) => state.area.selectedArea);
  const { data: areas } = useAreas();

  useEffect(() => {
    (async () => {
      if (!selectedArea?.image360Url) {
        return;
      }

      const getAreaDetail = (id: string) => {
        const area = areas?.find((area) => area._id === id);

        return area;
      };

      const showLoader = () => {
        const loaderEle = document.getElementsByClassName("aframe-loader")[0];
        if (loaderEle) {
          (loaderEle as HTMLElement).style.display = "flex";
        }
      };

      const addNavigationArrows = () => {
        if (currentBackground && aFrame) {
          selectedArea.paths?.forEach((path) => {
            aFrame.addImage({
              imageUrl: "/assets/navigation-arrow.png",
              id: path.name + "__navigation__",
              position: path.point.x + " " + path.point.y + " " + path.point.z,
              rotation:
                path.point.xRotation +
                " " +
                path.point.yRotation +
                " " +
                path.point.zRotation,
              width: "1.25",
              height: "1.25",
              onClick: () => {
                setArea(path.destionationArea);
              },
              animateType:
                getAreaDetail(selectedArea?.area)?.name === "Basin"
                  ? "bounceZ"
                  : "bounceX",
            });
          });
        }
      };

      showLoader();

      aFrame && aFrame?.addCubeMapSky(selectedArea.image360Url);
      aFrame && aFrame?.clearCurrentElements("navigation");

      addNavigationArrows();
    })();
  }, [
    currentBackground,
    selectedArea?.image360Url,
    selectedArea?.area,
    setArea,
    setPlaceHolder,
    selectedArea,
    aFrame,
    areas,
  ]);

  const hideHeader = () => {
    const header = document.getElementById("header");
    if (header) {
      header.classList.remove("z-10");
    }
  };
  const showHeader = () => {
    const header = document.getElementById("header");
    if (header) {
      header.classList.add("z-10");
    }
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      setIsFullScreen(false);
      showHeader();
      const aframeContainerEle = document.querySelector("#A-frame-container");
      if (aframeContainerEle) {
        aframeContainerEle.classList.add("relative");
        aframeContainerEle.classList.remove(
          "absolute",
          "z-10",
          "top-0",
          "h-screen",
          "w-screen"
        );
        const aframeEle = document.querySelector("#A-frame");
        (aframeEle as any).style.height = "100%";
        (aframeEle as any).style.width = "100%";
        (aframeEle as any).style.left = "0";
      }
    } else {
      setIsFullScreen(true);
      hideHeader();
      const aframeContainerEle = document.querySelector("#A-frame-container");
      if (aframeContainerEle) {
        const aspectRatio =
          aframeContainerEle.clientWidth / aframeContainerEle.clientHeight;
        aframeContainerEle.classList.remove("relative");
        aframeContainerEle.classList.add(
          "absolute",
          "z-10",
          "top-0",
          "h-screen",
          "w-screen"
        );
        const aframeEle = document.querySelector("#A-frame");
        (aframeEle as any).style.height = "100vh";
        (aframeEle as any).style.width = `calc(100vh * ${aspectRatio})`;
        (aframeEle as any).style.left = `calc(50% - ${
          (aframeEle as any).style.width
        } / 2)`;
      }
    }
  };

  return (
    <div
      className="overflow-hidden relative left-0  flex justify-center  flex-grow"
      id="A-frame-container"
    >
      <div className="w-full h-full absolute z-40" id="A-frame"></div>
      <div className="absolute flex flex-col z-40 p-1 m-1 right-0 cursor-pointer">
        <div
          onClick={toggleFullScreen}
          style={{ border: "1px solid rgba(133, 133, 133, 0.2)" }}
          className=" bg-white p-3 mb-4"
        >
          <img
            className="w-6 h-6"
            src={isFullScreen ? minimizeIcon : maximizeIcon}
            alt="fullscreen"
          />
        </div>
        <div
          onClick={() => setShowSharePopUp(true)}
          style={{ border: "1px solid rgba(133, 133, 133, 0.2)" }}
          className="p-3 bg-white"
          id="tour-share"
        >
          <ShareIcon className="w-6 h-6 cursor-pointer " />
        </div>
      </div>
      <ZoomToast />
      <div className="absolute flex flex-col items-center justify-center z-50 w-full h-full bg-white aframe-loader">
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p className="text-black mt-4 text-2xl tracking-widest">Loading...</p>
      </div>
    </div>
  );
}
