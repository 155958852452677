import { configureStore } from "@reduxjs/toolkit";

import {
  areaReducer,
  brandsReducer,
  miscReducer,
  pageReducer,
  productsReducer,
  stylesReducer,
} from "./globalState";

export const store = configureStore({
  reducer: {
    page: pageReducer,
    style: stylesReducer,
    brands: brandsReducer,
    products: productsReducer,
    area: areaReducer,
    misc: miscReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
