export const addText =
  (
    aScene: HTMLElement | null,
    aAssets: HTMLElement | null,
    currentElements: HTMLElement[] = []
  ) =>
  (
    text: string,
    id: string,
    position: string,
    rotation?: string,
    color?: string,
    fontSize?: string,
    onClick?: () => void,
    width?: string,
    height?: string
  ) => {
    if (!aScene || !aAssets) {
      console.warn("aScene or aAssets is null");
      return;
    }

    const prevAsset = document.getElementsByClassName("a-asset" + id)[0];
    if (prevAsset) {
      prevAsset.parentElement?.removeChild(prevAsset);
    }
    const aEntity = document.createElement("a-entity");
    onClick && (aEntity.onclick = onClick);
    const attributes: any = {
      position,
      rotation,
      geometry: `primitive: plane; width: ${width || 3.5}; height: ${
        height || 2
      }`,
      material: "color: #fff; opacity:0.8",
    };

    Object.keys(attributes).forEach((key: any) => {
      aEntity.setAttribute(key, attributes[key]);
    });
    const textEle = document.createElement("a-text");
    const textAttributes: any = {
      value: text,
      width: fontSize || "2",
      "wrap-count": "10",
      align: "center",
      color: color || "#000",
    };
    Object.keys(textAttributes).forEach((key: any) => {
      textEle.setAttribute(key, textAttributes[key]);
    });
    aEntity.appendChild(textEle);
    aEntity.className = "a-asset" + id + " clickable";
    aScene.appendChild(aEntity);
    currentElements.push(aEntity);
    return aEntity;
  };
