import React, { useState } from "react";

export const ZoomToast = () => {
  const [message] = useState("");

  // useEffect(() => {
  //   const removePartAdded = eventBus.on(["partAdded", "zoomOut"], () =>
  //     setMessage("Click on tap to zoom in")
  //   );
  //   const removeZoomIn = eventBus.on(["zoomIn"], () =>
  //     setMessage("Click on tap to zoom out")
  //   );
  //   const removeAreaChanged = eventBus.on(["areaChanged"], () =>
  //     setMessage("")
  //   );
  //   return () => {
  //     removePartAdded();
  //     removeZoomIn();
  //     removeAreaChanged();
  //     setMessage("");
  //   };
  // }, []);

  if (message === "") {
    return null;
  }
  return (
    <div className="absolute top-8 flex flex-col z-40 p-1 m-1 cursor-pointer">
      <h3 className="bg-white font-bold p-4 rounded-md opacity-70">
        {message}
      </h3>
    </div>
  );
};
