import { addOverLay } from "./addOverLay";
import { addTourGuide } from "./addTourGuide";
import { addWindow } from "./addWindow";
import { removeOverLay } from "./removeOverLay";
import { steps } from "./steps";

export const init = (
  actions: { onNext?: Function; onPrev?: Function }[],
  showOnlyOnce: boolean = true
): void => {
  const isTourShown = () => {
    return localStorage.getItem("tourShown") === "true";
  };

  if (isTourShown() && showOnlyOnce) {
    return;
  } else {
    localStorage.setItem("tourShown", "true");
  }

  const waitForElement = (step: typeof steps[0], index: number) => {
    const handle = setInterval(() => {
      const element = document.querySelector(step.selector);
      if (element) {
        clearInterval(handle);
        showStep(index);
      }
    }, 100);
  };

  const showStep = (index: number) => {
    if (index < 0) {
      return;
    }
    if (index >= steps.length || index < 0) {
      removeOverLay();
      return;
    }
    removeOverLay();
    const step = steps[index];

    const element = document.querySelector(step.selector);

    if (element) {
      const overLay = addOverLay();
      addWindow(overLay, element);
      addTourGuide(overLay, index, showStep, actions, element);
    } else {
      waitForElement(step, index);
    }
  };
  showStep(0);
};
